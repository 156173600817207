import React, { useEffect, useState } from 'react'
import group from "../assets/images/group.jpg"
import s1 from "../assets/images/home_banner/s1.webp";
import s2 from "../assets/images/home_banner/s2.webp";
import { Swiper, SwiperSlide } from 'swiper/react';
import "../assets/styles/home.css";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import architectural from "../assets/images/feature/architectural.jpg";
import construction from "../assets/images/feature/construction.jpg";
import reconstruction from "../assets/images/feature/reconstruction.jpg";
import g1 from "../assets/images/gallery/g1.webp";
import g2 from "../assets/images/gallery/g2.webp";
import g3 from "../assets/images/gallery/g3.webp";
import g4 from "../assets/images/gallery/g4.webp";
import g5 from "../assets/images/gallery/g5.webp";
import g6 from "../assets/images/gallery/g6.webp";
import t1 from "../assets/images/testimonials/t1.jpg"
import t2 from "../assets/images/testimonials/t2.jpg"
import t3 from "../assets/images/testimonials/t3.jpg"
import m1 from "../assets/images/team/m1.jpg"
import m2 from "../assets/images/team/m2.jpg"
import m3 from "../assets/images/team/m3.jpg"



// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Home = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const accordionData = [
        {
            title: "Expert Team",
            content: "Our team consists of highly trained professionals with years of experience in their respective fields, ensuring the highest level of service."
        },
        {
            title: "State-of-the-Art Technology",
            content: "We use the latest technology and advanced techniques to provide efficient and effective Services."
        },
        {
            title: "Patient-Centered Approach",
            content: "We prioritize your comfort, preferences, and satisfaction to deliver personalized care tailored to your needs."
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <>

            <div className="slider_swiper">

                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src={s1} alt="" style={{ height: "100vh", width: "100%" }} />
                        <div className="img_overlay"></div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6">
                                <div className="slider_content">
                                    <h1>THE CONSTRUCTION</h1>
                                    <p>Leading the way in Building</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={s2} alt="" style={{ height: "100vh", width: "100%" }} />
                        <div className="img_overlay"></div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6">
                                <div className="slider_content">
                                    <h1>THE BEST COMPANY</h1>
                                    <p>We are professional company over 20 years of Experince,<br /> We Wil brighten up every corner of Your House</p>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div>


            <section className="wprt-section intro">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>

                        <div className="col-md-4">

                            <div className="service-item clearfix">
                                <div className="thumb"><img src={construction} alt="image" /></div>
                                <div className="service-item-wrap">
                                    <h3 className="title font-size-18"><a href="#">Construction Consultant</a></h3>
                                    <p className="desc">Sed sit amet sapien sit amet odio lobortis ullamcorper quis vel nisl. Nam blandit maximus tristique. Vivamus enim quam, tincidunt vel blandit eu, molestie eget arcu.</p>
                                    {/* <div className="link">
                                        <a href="#" className="wprt-button small rounded-3px">READ MORE</a>
                                    </div> */}
                                </div>
                            </div>

                            <div className="wprt-spacer" data-desktop="0" data-mobi="40" data-smobi="40" ></div>
                        </div>

                        <div className="col-md-4">
                            <div className="service-item clearfix">
                                <div className="thumb"><img src={reconstruction} alt="image" /></div>
                                <div className="service-item-wrap">
                                    <h3 className="title font-size-18"><a href="#">Resconstruction Services</a></h3>
                                    <p className="desc">Sed sit amet sapien sit amet odio lobortis ullamcorper quis vel nisl. Nam blandit maximus tristique. Vivamus enim quam, tincidunt vel blandit eu, molestie eget arcu.</p>

                                </div>
                            </div>

                            <div className="wprt-spacer" data-desktop="0" data-mobi="40" data-smobi="40" ></div>
                        </div>

                        <div className="col-md-4">
                            <div className="service-item clearfix">
                                <div className="thumb"><img src={architectural} alt="image" /></div>
                                <div className="service-item-wrap">
                                    <h3 className="title font-size-18"><a href="#">Architectural Design</a></h3>
                                    <p className="desc">Sed sit amet sapien sit amet odio lobortis ullamcorper quis vel nisl. Nam blandit maximus tristique. Vivamus enim quam, tincidunt vel blandit eu, molestie eget.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="facts" className="wprt-section parallax" style={{
                backgroundAttachment: "fixed", backgroundPosition: "50% 129px"
            }}>
                <div div className="container" >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="100" data-mobi="60" data-smobi="60" style={{ height: "100px" }}></div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                            <div className="wprt-counter text-center accent-type">
                                <div className="number" data-speed="5000" data-to="1240" data-in-viewport="yes">1240</div>
                                <div className="text">PROJECT COMPLETED</div>
                            </div>

                            <div className="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30" ></div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                            <div className="wprt-counter text-center accent-type has-plus">
                                <div className="number" data-speed="5000" data-to="1750" data-in-viewport="yes">1750</div>
                                <div className="text">HAPPY CLIENTS</div>
                            </div>

                            <div className="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30" ></div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                            <div className="wprt-counter text-center accent-type">
                                <div className="number" data-speed="5000" data-to="984" data-in-viewport="yes">984</div>
                                <div className="text">WORKERS EMPLOYED</div>
                            </div>

                            <div className="wprt-spacer" data-desktop="0" data-mobi="30" data-smobi="30" ></div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                            <div className="wprt-counter text-center accent-type">
                                <div className="number" data-speed="5000" data-to="96" data-in-viewport="yes">96</div>
                                <div className="text">AWARDS WON</div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="103" data-mobi="60" data-smobi="60" style={{ height: "103px" }}></div>
                        </div>
                    </div>
                </div >
            </section >



            <section id="facts">
                <div className="col-md-12">
                    <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                </div>
                <div className="container" >


                    <h2 class="text-center margin-bottom-20">RECENT WORKS</h2>


                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <img src={g1} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <img src={g2} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <img src={g3} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <img src={g4} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <img src={g5} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <img src={g6} alt="" style={{ width: "100%" }} />
                        </div>

                    </div>

                </div>
                <div className="col-md-12">
                    <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                </div>
            </section >


            <section id="facts">
                <div className="col-md-12">
                    <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                </div>
                <div className="container" >



                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-sm-12">
                            <h2 class="margin-bottom-20  text-center">WHAT OTHER SAY ABOUT US</h2>

                            <div>


                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet iaculis elit. Nam semper ut arcu non placerat. Praesent nibh massa varius.


                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-lg-4 col-sm-12">
                            <div class="cbp-item"><div class="cbp-item-wrapper">
                                <div class="customer clearfix">
                                    <div class="inner">
                                        <div class="image"><img src={t1} alt="image" /></div>
                                        <h4 class="name">GEORGE SLOWS</h4>
                                        <div class="position">Builder manager</div>
                                        <blockquote class="whisper">Vestibulum eu libero volutpat, portas quam acc, tempus sem. Donec sodales quam id lorem lobortis, vitae interdum nisl vehicula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam suscipit, elit quis facilisis dictum, diam justo volutpat dui.</blockquote>
                                    </div>
                                </div>
                            </div></div>
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            <div class="cbp-item"><div class="cbp-item-wrapper">
                                <div class="customer clearfix">
                                    <div class="inner">
                                        <div class="image"><img src={t2} alt="image" /></div>
                                        <h4 class="name">GEORGE SLOWS</h4>
                                        <div class="position">Builder manager</div>
                                        <blockquote class="whisper">Vestibulum eu libero volutpat, portas quam acc, tempus sem. Donec sodales quam id lorem lobortis, vitae interdum nisl vehicula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam suscipit, elit quis facilisis dictum, diam justo volutpat dui.</blockquote>
                                    </div>
                                </div>
                            </div></div>
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            <div class="cbp-item"><div class="cbp-item-wrapper">
                                <div class="customer clearfix">
                                    <div class="inner">
                                        <div class="image"><img src={t3} alt="image" /></div>
                                        <h4 class="name">GEORGE SLOWS</h4>
                                        <div class="position">Builder manager</div>
                                        <blockquote class="whisper">Vestibulum eu libero volutpat, portas quam acc, tempus sem. Donec sodales quam id lorem lobortis, vitae interdum nisl vehicula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam suscipit, elit quis facilisis dictum, diam justo volutpat dui.</blockquote>
                                    </div>
                                </div>
                            </div></div>
                        </div>
                    </div>
                </div>

            </section >


            <section id="facts">
                <div className="col-md-12">
                    <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <img src={group} alt="" style={{ width: "100%" }} />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div class="wprt-content-box style-2">
                                <h2 class="margin-bottom-20">WHY CHOOSE US?</h2>
                            </div>
                            <div className="acc_sec">
                                {accordionData.map((item, index) => (
                                    <div key={index} className="accordion-item">
                                        <div className="accordion-title" onClick={() => toggleAccordion(index)}>
                                            <h3 style={{ color: "#fff" }}>{item.title}</h3>
                                            <span>{activeIndex === index ? "-" : "+"}</span>
                                        </div>
                                        {activeIndex === index && (
                                            <div className="accordion-content">
                                                <p>{item.content}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                </div>
            </section>


            <section id="facts">


                <div className="container">
                    <div class="wprt-content-box style-2">
                        <h2 class="margin-bottom-20">Our Team</h2>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div class="cbp-item-wrapper">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src={m1} alt="image" />
                                            </div>

                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Sarah Spence</h4>
                                            <div class="position">Construction Assistant</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            <div class="cbp-item-wrapper">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src={m2} alt="image" />
                                            </div>

                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Sarah Spence</h4>
                                            <div class="position">Construction Assistant</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div class="cbp-item-wrapper">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src={m3} alt="image" />
                                            </div>

                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Sarah Spence</h4>
                                            <div class="position">Construction Assistant</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-12">
                    <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                </div>
            </section>

        </>


    )
}

export default Home
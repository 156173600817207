import React, { useEffect, useState } from 'react'
import "../assets/styles/services.css"
import { TiTick } from 'react-icons/ti';
import home_reno_pre from "../assets/images/services/home_reno_pre.jpg";
import home_ren_after from "../assets/images/services/home_ren_after.jpg"
import Sidebar from '../layout/Sidebar';

const HomeRenovation = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const accordionData = [
        {
            title: "Planning Your Renovation",
            content: "Successful renovations start with a clear vision. Define your goals, budget, and style preferences to ensure the project meets your needs. Collaborating with designers and contractors helps bring your ideas to life while keeping the renovation on schedule and within budget. "
        },
        {
            title: "Kitchen and Bathroom Upgrades",
            content: "Kitchens and bathrooms are top choices for renovations, as upgrades here add value and improve daily comfort. Popular changes include modern cabinetry, new countertops, energy-efficient appliances, and contemporary fixtures. "
        },
        {
            title: "Living Spaces and Bedrooms",
            content: "Renovating living rooms, bedrooms, and other areas can enhance comfort and style. Changes might include updated flooring, lighting, wall finishes, or even structural adjustments to open up the layout. These upgrades make spaces more inviting and tailored to your lifestyle."
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div id="featured-title" class="clearfix featured-title-left">
                <div id="featured-title-inner" class="container clearfix">
                    <div class="featured-title-inner-wrap">
                        <div class="featured-title-heading-wrap">
                            <h1 class="featured-title-heading">Home Renovation</h1>
                        </div>
                        <div id="breadcrumbs">
                            <div class="breadcrumbs-inner">
                                <div class="breadcrumb-trail">
                                    <a href="#" title="Construction" rel="home" class="trail-begin">Home</a>
                                    <span class="sep">/</span>
                                    <span class="trail-end">Home Renovation</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services_sec">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <Sidebar />
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <h3 class="line-height-normal margin-bottom-10">WHAT IS INCLUDED</h3>
                            <p class="margin-bottom-0">Home renovation is a transformative process that refreshes and revitalizes living spaces, whether you're updating a single room or remodeling the entire house. Renovations can improve functionality, enhance aesthetics, and increase property value. They allow homeowners to modernize outdated designs, install energy-efficient fixtures, and make structural changes that better suit their lifestyle.

                                The renovation process begins with a vision, where you outline your goals, budget, and preferred style. Working with professional designers and contractors ensures that every detail, from layout to materials, is carefully planned to achieve a cohesive look and durable result. Renovations can include everything from kitchen and bathroom upgrades to flooring replacements, lighting enhancements, and the installation of smart home systems.</p>

                            <div className="acc_sec services_acc">
                                {accordionData.map((item, index) => (
                                    <div key={index} className="accordion-item">
                                        <div className="accordion-title" onClick={() => toggleAccordion(index)}>

                                            <h4>{item.title}</h4>
                                            <span>{activeIndex === index ? "-" : "+"}</span>
                                        </div>
                                        {activeIndex === index && (
                                            <div className="accordion-content">
                                                <p>{item.content}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>


                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>


                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={home_reno_pre} alt="" width="100%" />
                                    </div>
                                    <h3>Pre Construction</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat corporis sint sunt! Ullam molestiae et voluptas atque. Ullam, mollitia?</p>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={home_ren_after} alt="" width="100%" />
                                    </div>
                                    <h3>After the Project</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid id quam modi a possimus natus voluptatem numquam voluptates ex dolorum?</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeRenovation
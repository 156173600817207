import "../assets/styles/about.css";
import architectural from "../assets/images/feature/architectural.jpg";
import construction from "../assets/images/feature/construction.jpg";
import reconstruction from "../assets/images/feature/reconstruction.jpg";
import { Link } from "react-router-dom";
import m1 from "../assets/images/team/m1.jpg"
import m2 from "../assets/images/team/m2.jpg"
import m3 from "../assets/images/team/m3.jpg"


const About = () => {
    return (
        <>
            <div id="featured-title" class="clearfix featured-title-left">
                <div id="featured-title-inner" class="container clearfix">
                    <div class="featured-title-inner-wrap">
                        <div class="featured-title-heading-wrap">
                            <h1 class="featured-title-heading">About Us</h1>
                        </div>
                        <div id="breadcrumbs">
                            <div class="breadcrumbs-inner">
                                <div class="breadcrumb-trail">
                                    <a href="#" title="Construction" rel="home" class="trail-begin">Home</a>
                                    <span class="sep">/</span>
                                    <span class="trail-end">About Us</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="wprt-section intro">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>

                        <div className="col-md-4">

                            <div className="service-item clearfix">
                                <div className="thumb"><img src={construction} alt="image" /></div>
                                <div className="service-item-wrap">
                                    <h3 className="title font-size-18"><a href="#">Construction Consultant</a></h3>
                                    <p className="desc">Sed sit amet sapien sit amet odio lobortis ullamcorper quis vel nisl. Nam blandit maximus tristique. Vivamus enim quam, tincidunt vel blandit eu, molestie eget arcu.</p>
                                    {/* <div className="link">
                                        <a href="#" className="wprt-button small rounded-3px">READ MORE</a>
                                    </div> */}
                                </div>
                            </div>

                            <div className="wprt-spacer" data-desktop="0" data-mobi="40" data-smobi="40" ></div>
                        </div>

                        <div className="col-md-4">
                            <div className="service-item clearfix">
                                <div className="thumb"><img src={reconstruction} alt="image" /></div>
                                <div className="service-item-wrap">
                                    <h3 className="title font-size-18"><a href="#">Resconstruction Services</a></h3>
                                    <p className="desc">Sed sit amet sapien sit amet odio lobortis ullamcorper quis vel nisl. Nam blandit maximus tristique. Vivamus enim quam, tincidunt vel blandit eu, molestie eget arcu.</p>

                                </div>
                            </div>

                            <div className="wprt-spacer" data-desktop="0" data-mobi="40" data-smobi="40" ></div>
                        </div>

                        <div className="col-md-4">
                            <div className="service-item clearfix">
                                <div className="thumb"><img src={architectural} alt="image" /></div>
                                <div className="service-item-wrap">
                                    <h3 className="title font-size-18"><a href="#">Architectural Design</a></h3>
                                    <p className="desc">Sed sit amet sapien sit amet odio lobortis ullamcorper quis vel nisl. Nam blandit maximus tristique. Vivamus enim quam, tincidunt vel blandit eu, molestie eget.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="facts" className="wprt-section parallax" style={{
                backgroundAttachment: "fixed", backgroundPosition: "50% 129px"
            }}>
                <div div className="container" >
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-sm-12">
                            <div className="demo_container">
                                <h3>WE BUILD PROFESSIONAL AND SMART BUILDINGS
                                </h3>
                                <Link to="/service-detail/bedroom-kitchen-fitting">
                                    <button name="submit" type="submit" value="Submit" class="site-button  m-r15">What We Do</button>
                                </Link>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="103" data-mobi="60" data-smobi="60" style={{ height: "103px" }}></div>
                        </div>
                    </div>
                </div >
            </section >


            <section id="facts">


                <div className="container">
                    <div class="wprt-content-box style-2">
                        <h2 class="margin-bottom-20">Our Team</h2>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div class="cbp-item-wrapper">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src={m1} alt="image" />
                                            </div>

                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Sarah Spence</h4>
                                            <div class="position">Construction Assistant</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-12">
                            <div class="cbp-item-wrapper">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src={m2} alt="image" />
                                            </div>

                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Sarah Spence</h4>
                                            <div class="position">Construction Assistant</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div class="cbp-item-wrapper">
                                <div class="member">
                                    <div class="inner">
                                        <div class="image">
                                            <div class="inner">
                                                <img src={m3} alt="image" />
                                            </div>

                                        </div>
                                        <div class="texts">
                                            <h4 class="name">Sarah Spence</h4>
                                            <div class="position">Construction Assistant</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-12">
                    <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                </div>
            </section>

        </>
    )
}

export default About
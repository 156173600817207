import React, { useEffect, useState } from 'react'
import "../assets/styles/services.css"
import { TiTick } from 'react-icons/ti';
import bedroom_after from "../assets/images/services/bedroom_after.jpg";
import bedroom_pre from "../assets/images/services/bedroom_pre.jpg"
import Sidebar from '../layout/Sidebar';

const BedroomFitting = () => {


    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const accordionData = [
        {
            title: "Tailored Wardrobes",
            content: "Custom wardrobes designed to fit your space, featuring shelves, hanging areas, and drawers."
        },
        {
            title: "Smart Storage Solutions",
            content: "Utilize under-bed storage and built-in shelving to keep your room organized and clutter-free."
        },
        {
            title: "Stylish Finishes",
            content: "Choose from a range of materials and finishes to create a cohesive look that reflects your style."
        }
    ];


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div id="featured-title" class="clearfix featured-title-left">
                <div id="featured-title-inner" class="container clearfix">
                    <div class="featured-title-inner-wrap">
                        <div class="featured-title-heading-wrap">
                            <h1 class="featured-title-heading">Bedroom & Kitchen Fitting</h1>
                        </div>
                        <div id="breadcrumbs">
                            <div class="breadcrumbs-inner">
                                <div class="breadcrumb-trail">
                                    <a href="#" title="Construction" rel="home" class="trail-begin">Home</a>
                                    <span class="sep">/</span>
                                    <span class="trail-end">Bedroom & Kitchen Fitting</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services_sec">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <Sidebar />
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <h3 class="line-height-normal margin-bottom-10">WHAT IS INCLUDED</h3>
                            <p class="margin-bottom-0">
                                Bedroom and kitchen fitting are essential services that transform spaces into functional and stylish areas tailored to your lifestyle. For bedrooms, fitting involves custom solutions that maximize storage, enhance layout, and incorporate sleek finishes that create a peaceful, organized space. From wardrobes and drawers to floating shelves and bedside tables, each element is crafted to meet your needs, using high-quality materials that ensure durability and elegance.

                                In kitchens, fittings are designed to blend aesthetics with practicality. Custom cabinetry, countertops, and smart storage solutions create an efficient, welcoming environment for cooking and gathering. With various options in colors, materials, and finishes, the kitchen fitting process ensures every detail reflects your taste and fits seamlessly into your home. Thoughtful design elements, like pull-out pantry units and integrated appliances, enhance functionality while keeping the kitchen visually cohesive.</p>

                            <div className="acc_sec services_acc">
                                {accordionData.map((item, index) => (
                                    <div key={index} className="accordion-item">
                                        <div className="accordion-title" onClick={() => toggleAccordion(index)}>

                                            <h4>{item.title}</h4>
                                            <span>{activeIndex === index ? "-" : "+"}</span>
                                        </div>
                                        {activeIndex === index && (
                                            <div className="accordion-content">
                                                <p>{item.content}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>


                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>


                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={bedroom_pre} alt="" width="100%" />
                                    </div>
                                    <h3>Pre Construction</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat corporis sint sunt! Ullam molestiae et voluptas atque. Ullam, mollitia?</p>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={bedroom_after} alt="" width="100%" />
                                    </div>
                                    <h3>After the Project</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid id quam modi a possimus natus voluptatem numquam voluptates ex dolorum?</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default BedroomFitting
import React, { useEffect, useState } from 'react'
import "../assets/styles/services.css"
import { TiTick } from 'react-icons/ti';
import brickwork_after from "../assets/images/services/brickwork_after.jpg";
import brickwork_pre from "../assets/images/services/brickwork_pre.jpg"
import Sidebar from '../layout/Sidebar';
const BrickworksServices = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const accordionData = [
        {
            title: "Residential Brickwork",
            content: "Enhance your home with brickwork solutions tailored to your outdoor spaces. From garden walls to patios and home extensions, our brickwork services bring functionality and charm. Each project is designed to blend seamlessly with your property while providing a timeless look that increases curb appeal."
        },
        {
            title: "Commercial Brickwork",
            content: "For commercial properties, we provide reliable brick construction for offices, retail spaces, and building facades. Our work combines structural integrity with a professional finish, creating a strong and appealing presence that enhances the property’s appearance and value. "
        },
        {
            title: "Brick Restoration",
            content: "Preserve the beauty and history of your property with our brick restoration services. We repair crumbling mortar, replace damaged bricks, and restore older structures, maintaining their original charm while ensuring structural stability. Ideal for historical buildings and residential restorations."
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <>
            <div id="featured-title" class="clearfix featured-title-left">
                <div id="featured-title-inner" class="container clearfix">
                    <div class="featured-title-inner-wrap">
                        <div class="featured-title-heading-wrap">
                            <h1 class="featured-title-heading">Brickwork Services</h1>
                        </div>
                        <div id="breadcrumbs">
                            <div class="breadcrumbs-inner">
                                <div class="breadcrumb-trail">
                                    <a href="#" title="Construction" rel="home" class="trail-begin">Home</a>
                                    <span class="sep">/</span>
                                    <span class="trail-end">Brickwork Services</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services_sec">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <Sidebar />
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <h3 class="line-height-normal margin-bottom-10">WHAT IS INCLUDED</h3>
                            <p class="margin-bottom-0">Brickwork services play a crucial role in building, repairing, and enhancing various structures with durability and aesthetic appeal. These services encompass a wide range of masonry tasks, from constructing new walls, foundations, and fireplaces to repairing and restoring older brick structures. Skilled bricklayers use high-quality materials and expert techniques to create solid, attractive builds that withstand time and weather.

                                In residential projects, brickwork is often used for garden walls, patios, and extensions, adding both function and charm to outdoor spaces. For commercial properties, brickwork provides sturdy and reliable construction for offices, retail spaces, and facades, combining structural integrity with a professional look. Restoration services are also a key component, where experienced bricklayers repair crumbling mortar, replace damaged bricks, and preserve the historical integrity of older buildings.</p>

                            <div className="acc_sec services_acc">
                                {accordionData.map((item, index) => (
                                    <div key={index} className="accordion-item">
                                        <div className="accordion-title" onClick={() => toggleAccordion(index)}>

                                            <h4>{item.title}</h4>
                                            <span>{activeIndex === index ? "-" : "+"}</span>
                                        </div>
                                        {activeIndex === index && (
                                            <div className="accordion-content">
                                                <p>{item.content}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>


                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>


                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={brickwork_pre} alt="" width="100%" />
                                    </div>
                                    <h3>Pre Construction</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat corporis sint sunt! Ullam molestiae et voluptas atque. Ullam, mollitia?</p>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={brickwork_after} alt="" width="100%" />
                                    </div>
                                    <h3>After the Project</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid id quam modi a possimus natus voluptatem numquam voluptates ex dolorum?</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BrickworksServices
import React from 'react'
import constructiom from "../assets/images/constructiom.jpg";
import preconstructiom from "../assets/images/preconstructiom.jpg"
import project from "../assets/images/project.jpg"
import "../assets/styles/project.css"

const Projects = () => {
    return (
        <>

            <div id="featured-title" class="clearfix featured-title-left">
                <div id="featured-title-inner" class="container clearfix">
                    <div class="featured-title-inner-wrap">
                        <div class="featured-title-heading-wrap">
                            <h1 class="featured-title-heading">Projects</h1>
                        </div>
                        <div id="breadcrumbs">
                            <div class="breadcrumbs-inner">
                                <div class="breadcrumb-trail">
                                    <a href="#" title="Construction" rel="home" class="trail-begin">Home</a>
                                    <span class="sep">/</span>
                                    <span class="trail-end">Projects</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div class="col-md-12">
                    <div class="wprt-spacer" data-desktop="70" data-mobi="40" data-smobi="40" style={{ height: "70px" }}></div>
                    <h2 class="text-center font-size-28 margin-bottom-10">PORTFOLIO SLIDER</h2>

                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-sm-12">
                            <div class="wprt-spacer" data-desktop="25" data-mobi="25" data-smobi="25" style={{ height: "25px" }}></div>
                            <p class="wprt-subtitle text-center">Cras id justo eget sapien scelerisque lacinia non a eros. In a volutpat magna. Vivamus pretium urna at condimentum porta. Phasellus mollis mauris dolor, a tincidunt mauris fringilla.</p>
                        </div>
                    </div>
                    <div class="wprt-spacer" data-desktop="43" data-mobi="30" data-smobi="30" style={{ height: "43px" }}></div>
                </div>
                <div className="container">
                    <div className="row" style={{ paddingBottom: "30px" }}>
                    <div className="col-lg-4 col-sm-12">
                            <div class="cbp-item-wrapper">
                            <div class="project-item">
                                <div class="inner">
                                    <figure class="effect-oscar">
                                            <img src={constructiom} alt="image" style={{ width: "100%" }} />
                                            <div className="img_overlay"></div>

                                        <figcaption>
                                            <div>
                                                    <h2>LUXURY BUILDINGS</h2>
                                                <p>Construction</p>
                                            </div>
                                        </figcaption>
                                    </figure>

                                    <a class="project-zoom cbp-lightbox" href="assets/img/projects/2-full.jpg" data-title="LUXURY BUILDINGS">
                                        <i class="fa fa-arrows-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-4 col-sm-12">
                        <div class="cbp-item-wrapper">
                            <div class="project-item">
                                <div class="inner">
                                    <figure class="effect-oscar">
                                            <img src={project} alt="image" style={{ width: "100%" }} />
                                            <div className="img_overlay"></div>
                                        <figcaption>
                                            <div>
                                                <h2><a target="_blank" href="page-project-detail.html">LUXURY BUILDINGS</a></h2>
                                                <p>Construction</p>
                                            </div>
                                        </figcaption>
                                    </figure>

                                    <a class="project-zoom cbp-lightbox" href="assets/img/projects/2-full.jpg" data-title="LUXURY BUILDINGS">
                                        <i class="fa fa-arrows-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-lg-4 col-sm-12">
                        <div class="cbp-item-wrapper">
                            <div class="project-item">
                                <div class="inner">
                                    <figure class="effect-oscar">
                                            <img src={preconstructiom} alt="image" style={{ width: "100%" }} />
                                            <div className="img_overlay"></div>
                                        <figcaption>
                                            <div>
                                                <h2><a target="_blank" href="page-project-detail.html">LUXURY BUILDINGS</a></h2>
                                                <p>Construction</p>
                                            </div>
                                        </figcaption>
                                    </figure>

                                    <a class="project-zoom cbp-lightbox" href="assets/img/projects/2-full.jpg" data-title="LUXURY BUILDINGS">
                                        <i class="fa fa-arrows-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>



        </>
    )
}

export default Projects
import React, { useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { FiMinus } from 'react-icons/fi';
import { GoPlus } from 'react-icons/go';
import "../assets/styles/responsivenavbar.css"
import loft from "../assets/images/loft.png"
import loft_logo_dark from "../assets/images/loft_logo_dark.png"


const ResponsiveNavbar = () => {

    const [resposiveMenu, setResponsiveMenu] = useState(false);
    const [servicesMenu, setServicesMenu] = useState(null);

    const callResponsive = () => {
        setResponsiveMenu(false)
    }

    return (
        <div className="responsive_navbar">
            <div className="container">
                <nav style={{ padding: "10px" }}>
                    {/* <div className="col-lg-6 col-sm-6">
                        <div className="navbar_left">
                            <div className="logo_area">
                                <Link to="/">
                                    <img src={loft} alt="" style={{ width: "100px" }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <div className="navbar_right">
                            <GiHamburgerMenu
                                fontSize="20px"
                                onClick={() => setResponsiveMenu(!resposiveMenu)}
                                style={{ color: "#000" }}
                            />
                        </div>
                    </div> */}

                    <div className="nav_group">
                        <div className="navbar_left">
                            <div className="logo_area">
                                <Link to="/">
                                    <img src={loft_logo_dark} alt="" style={{ width: "80px" }} />
                                </Link>
                            </div>
                        </div>
                        <div className="navbar_right">
                            <GiHamburgerMenu
                                fontSize="20px"
                                onClick={() => setResponsiveMenu(!resposiveMenu)}
                                style={{ color: "#000" }}
                            />
                        </div>
                    </div>
                </nav>

                {resposiveMenu && (
                    <div className="responsive_menu_wrapper animate__animated animate__fadeInDown">
                        <div className="close_btn">
                            <IoMdCloseCircleOutline
                                fontSize="30px"
                                onClick={() => setResponsiveMenu(!resposiveMenu)}
                            />
                        </div>

                        <div className="resp_menu_list">
                            <ul className="resp_menu_items">
                                <li onClick={callResponsive}>
                                    <Link to="/">Home</Link>
                                </li>
                                <li onClick={callResponsive}>
                                    <Link to="/about">About US</Link>
                                </li>
                                <li
                                    onClick={() =>
                                        setServicesMenu(
                                            servicesMenu === "services" ? null : "services"
                                        )
                                    }
                                >
                                    <Link>Our Services</Link>
                                    {servicesMenu === "services" ? <FiMinus /> : <GoPlus />}
                                </li>

                                {servicesMenu === "services" && (
                                    <ul className="responsive_submenu">
                                        <li onClick={callResponsive}>
                                            <Link to="/services/bedroom-kitchen-fitting" >
                                                Bedroom & Kitchen Fitting
                                            </Link>
                                        </li>
                                        <li onClick={callResponsive}>
                                            <Link to="/services/brickwork-services">
                                                Brickwork Services
                                            </Link>
                                        </li>
                                        <li onClick={callResponsive}>
                                            <Link to="/services/home-renovation">
                                                Home Renovations
                                            </Link>
                                        </li>

                                        <li onClick={callResponsive}>
                                            <Link to="/services/home-extension">
                                                Home Extension
                                            </Link>
                                        </li>
                                        <li onClick={callResponsive}>
                                            <Link to="/services/loft-conversion">
                                                Loft Conversion
                                            </Link>
                                        </li>
                                        <li onClick={callResponsive}>
                                            <Link to="/services/new-builds">
                                                New Builds
                                            </Link>
                                        </li>
                                    </ul>
                                )}


                                <li
                                    onClick={callResponsive}
                                >
                                    <Link to="/project">Our Project</Link>
                                </li>


                                <li onClick={callResponsive}>
                                    <Link to="/career">Career</Link>
                                </li>
                                <li onClick={callResponsive}>
                                    <Link to="/gallery">Gallery</Link>
                                </li>
                                <li onClick={callResponsive}>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ResponsiveNavbar
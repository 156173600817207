import React, { useEffect, useState } from 'react'
import "../assets/styles/services.css"
import { TiTick } from 'react-icons/ti';
import home_ext_pre from "../assets/images/services/home_ext_pre.jpg"
import home_ext_after from "../assets/images/services/home_ext_after.jpg"
import Sidebar from '../layout/Sidebar';

const HomeExtension = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const accordionData = [
        {
            title: "Design and Planning",
            content: "A successful extension requires careful planning and design. Our architects work with you to create a layout that blends seamlessly with the existing structure while meeting local regulations. From material selection to energy-efficient designs, each step is meticulously planned to ensure lasting quality and style."
        },
        {
            title: "Construction and Quality Assurance",
            content: "Our skilled builders ensure that each home extension is constructed to the highest standards. We manage every detail, from foundations to final finishes, to deliver a seamless, polished result. With careful project management, we minimize disruption to your daily life, ensuring a smooth construction process."
        }
    ];


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <div id="featured-title" class="clearfix featured-title-left">
                <div id="featured-title-inner" class="container clearfix">
                    <div class="featured-title-inner-wrap">
                        <div class="featured-title-heading-wrap">
                            <h1 class="featured-title-heading">Home Extension</h1>
                        </div>
                        <div id="breadcrumbs">
                            <div class="breadcrumbs-inner">
                                <div class="breadcrumb-trail">
                                    <a href="#" title="Construction" rel="home" class="trail-begin">Home</a>
                                    <span class="sep">/</span>
                                    <span class="trail-end">Home Extension</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services_sec">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <Sidebar />
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <h3 class="line-height-normal margin-bottom-10">WHAT IS INCLUDED</h3>
                            <p class="margin-bottom-0">A home extension is an excellent way to add space, value, and functionality to your property without the hassle of moving. Whether it’s expanding your kitchen, creating an extra bedroom, or adding a new living area, extensions offer a customized solution that fits your family’s needs. Tailored to blend seamlessly with the existing structure, well-designed extensions enhance both the look and utility of your home.

                                Professional home extension services involve careful planning and design to ensure that the new space is structurally sound, energy-efficient, and in line with local regulations. Architects and builders work closely with you to choose materials, layouts, and finishes that suit your style while ensuring longevity and quality.</p>

                            <div className="acc_sec services_acc">
                                {accordionData.map((item, index) => (
                                    <div key={index} className="accordion-item">
                                        <div className="accordion-title" onClick={() => toggleAccordion(index)}>

                                            <h4>{item.title}</h4>
                                            <span>{activeIndex === index ? "-" : "+"}</span>
                                        </div>
                                        {activeIndex === index && (
                                            <div className="accordion-content">
                                                <p>{item.content}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>


                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>


                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={home_ext_pre} alt="" width="100%" />
                                    </div>
                                    <h3>Pre Construction</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat corporis sint sunt! Ullam molestiae et voluptas atque. Ullam, mollitia?</p>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={home_ext_after} alt="" width="100%" />
                                    </div>
                                    <h3>After the Project</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid id quam modi a possimus natus voluptatem numquam voluptates ex dolorum?</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeExtension
import React, { useEffect, useState } from 'react'
import "../assets/styles/services.css"
import { TiTick } from 'react-icons/ti';
import new_con_pre from "../assets/images/services/new_con_pre.jpg"
import new_con_after from "../assets/images/services/new_con_after.jpg"
import Sidebar from '../layout/Sidebar';

const NewBuilds = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const accordionData = [
        {
            title: "Customization and Personalization",
            content: "One of the significant advantages of new builds is the opportunity for customization. Buyers can tailor layouts and finishes to suit their preferences and lifestyle needs, choosing everything from floor plans to materials. This level of personalization ensures the final product perfectly fits the homeowner's vision."
        },
        {
            title: "Modern Technologies and Efficiency",
            content: "New builds often incorporate the latest building technologies, enhancing energy efficiency and sustainability. Features such as improved insulation, smart home integrations, and the use of sustainable materials lead to lower long-term maintenance costs and increased comfort for occupants."
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>

            <div id="featured-title" class="clearfix featured-title-left">
                <div id="featured-title-inner" class="container clearfix">
                    <div class="featured-title-inner-wrap">
                        <div class="featured-title-heading-wrap">
                            <h1 class="featured-title-heading">New Builds</h1>
                        </div>
                        <div id="breadcrumbs">
                            <div class="breadcrumbs-inner">
                                <div class="breadcrumb-trail">
                                    <a href="#" title="Construction" rel="home" class="trail-begin">Home</a>
                                    <span class="sep">/</span>
                                    <span class="trail-end">New Builds</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services_sec">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <Sidebar />
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <h3 class="line-height-normal margin-bottom-10">WHAT IS INCLUDED</h3>
                            <p class="margin-bottom-0">New builds refer to the construction of residential or commercial properties from the ground up, offering a blank canvas for homeowners and developers alike. This process begins with careful planning, including site selection, architectural design, and obtaining necessary permits. New builds allow for modern design elements and energy-efficient features, making them highly desirable in today’s market.

                                One of the primary advantages of new builds is the opportunity to customize the layout and finishes according to personal preferences and lifestyle needs. From choosing floor plans to selecting materials, buyers can create a home that perfectly fits their vision. Additionally, new builds often incorporate the latest building technologies, ensuring improved insulation, sustainable materials, and smart home integrations, resulting in lower long-term maintenance costs and enhanced comfort.</p>

                            <div className="acc_sec services_acc">
                                {accordionData.map((item, index) => (
                                    <div key={index} className="accordion-item">
                                        <div className="accordion-title" onClick={() => toggleAccordion(index)}>

                                            <h4>{item.title}</h4>
                                            <span>{activeIndex === index ? "-" : "+"}</span>
                                        </div>
                                        {activeIndex === index && (
                                            <div className="accordion-content">
                                                <p>{item.content}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>


                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>


                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={new_con_pre} alt="" width="100%" />
                                    </div>
                                    <h3>Pre Construction</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat corporis sint sunt! Ullam molestiae et voluptas atque. Ullam, mollitia?</p>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={new_con_after} alt="" width="100%" />
                                    </div>
                                    <h3>After the Project</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid id quam modi a possimus natus voluptatem numquam voluptates ex dolorum?</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default NewBuilds
import React, { useState } from "react";
import "../assets/styles/navbar.css";
import {
    FaFacebookF,
    FaInstagram,
    FaLinkedinIn,
    FaPhone,
    FaTwitter,
} from "react-icons/fa";
import { BiLogoFacebookSquare } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import loft from "../assets/images/loft.png"
import { MdKeyboardArrowDown } from "react-icons/md";
import loft_logo_dark from "../assets/images/loft_logo_dark.png"

const Navbar = () => {
    const [currentLink, setCurrentLink] = useState(null);

    const handleMouseEnter = (link) => {
        setCurrentLink(link);
    };

    const handleMouseLeave = () => {
        setCurrentLink(null);
    };

    const location = useLocation();

    // Check if the current path is the homepage
    const isHomePage = location.pathname === '/';

    return (
        <section className={isHomePage ? "header" : "homepage_relative"}>

            <div className="rbt-header-wrapper header-sticky header-space-betwween">
                <div class="mainbar-row rbt-navigation-center align-items-center">
                    <div class="header-left rbt-header-content">
                        <div class="header-info">
                            <div class="logo">
                                <Link to="/" title="HiStudy" rel="home">
                                    <img src={loft} alt="HiStudy" className="light_logo" style={{ width: "100px" }} />
                                    <img src={loft_logo_dark} alt="" className="dark_logo" style={{ width: "100px" }} />
                                </Link>
                            </div>{" "}
                        </div>
                    </div>
                    <div class="rbt-main-navigation d-none d-xl-block ">
                        <nav class="mainmenu-nav">
                            <ul className="mainmenu">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About US</Link>
                                </li>
                                <li
                                    onMouseEnter={() => handleMouseEnter("services")}
                                    className="main_menu_link"
                                >
                                    <Link>
                                        Our Services <MdKeyboardArrowDown />
                                    </Link>
                                    {(
                                     currentLink === "services" && 
                                <div class="mega-box" onMouseLeave={handleMouseLeave}>

                                             <div className="row dropdown_list">
                                                 <div className="col-lg-12">
                                                        {/* <em>Our Courses</em> */}
                                                     <ul>
                                                     <li>
                                                                <Link to="/services/bedroom-kitchen-fitting">
                                                                    Bedroom & Kitchen Fitting
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/services/brickwork-services">
                                                                    Brickwork Services
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/services/home-renovation">
                                                                    Home Renovations
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/services/home-extension">
                                                                    Home Extension
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/services/loft-conversion">
                                                                    Loft Conversion
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/services/new-builds">
                                                                    New Builds
                                                                </Link>
                                                            </li>
                                                     </ul>
                                                    </div>

                                            </div>
                                        </div> 
                                    )} 
                                </li>
                                <li>
                                    <Link to="/project">Our Projects</Link>
                                </li>
                                <li>
                                    <Link to="/career">Career</Link>
                                </li>
                                <li>
                                    <Link to="/gallery">Gallery</Link>
                                </li>

                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default Navbar;

import React from 'react'
import "../assets/styles/contact.css"

const Contact = () => {
    return (
        <>

            <div id="featured-title" class="clearfix featured-title-left">
                <div id="featured-title-inner" class="container clearfix">
                    <div class="featured-title-inner-wrap">
                        <div class="featured-title-heading-wrap">
                            <h1 class="featured-title-heading">Contact</h1>
                        </div>
                        <div id="breadcrumbs">
                            <div class="breadcrumbs-inner">
                                <div class="breadcrumb-trail">
                                    <a href="#" title="Construction" rel="home" class="trail-begin">Home</a>
                                    <span class="sep">/</span>
                                    <span class="trail-end">Contact</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d24717.818724225013!2d106.890492!3d11.534407!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sus!4v1731135541221!5m2!1sen!2sus" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>



            <section class="wprt-section">

                <div class="wprt-spacer" data-desktop="100" data-mobi="60" data-smobi="60" style={{ height: "40px" }}></div>

                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="text-center margin-bottom-20">GET IN TOUCH WITH US</h2>


                            <div class="wprt-spacer" data-desktop="36" data-mobi="30" data-smobi="30" ></div>

                            <p class="wprt-subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet iaculis elit. Nam semper ut arcu non placerat. Praesent nibh massa varius.</p>
                            <div class="wprt-spacer" data-desktop="40" data-mobi="30" data-smobi="30" style={{ height: "40px" }}></div>
                        </div>
                        <div class="col-md-4">
                            <h5>Address</h5>
                            <p>68 Havemeyer St, Brooklyn, NY 11211<br /> United States</p>

                            <div class="wprt-spacer" data-desktop="15" data-mobi="0" data-smobi="0" style={{ height: "15px" }}></div>

                            <h5>Phone number</h5>
                            <p>+1 718-955-2838 or +1 718-955-3290</p>

                            <div class="wprt-spacer" data-desktop="15" data-mobi="0" data-smobi="0" style={{ height: "15px" }}></div>

                            <h5>E-mail address</h5>
                            <p>contact@construction.com</p>

                            <div class="wprt-spacer" data-desktop="0" data-mobi="10" data-smobi="10" style={{ height: "15px" }}></div>
                        </div>

                        <div class="col-md-8 col-sm-12">
                            <form class="wprt-contact-form" method="post" novalidate="novalidate">
                                <div class="inner">
                                    <div class="left-side">
                                        <div class="input-wrap">
                                            <input type="text" value="" tabindex="1" placeholder="Name *" name="name" id="name" required="" />
                                        </div>
                                        <div class="input-wrap">
                                            <input type="email" value="" tabindex="2" placeholder="Email *" name="email" id="email" required="" />
                                        </div>
                                        <div class="input-wrap">
                                            <input type="text" value="" tabindex="4" placeholder="Subject *" name="subject" id="subject" required="" />
                                        </div>
                                        <div class="message-wrap">
                                            <textarea class="" tabindex="5" placeholder="Message *" name="message" id="message" required=""></textarea>
                                        </div>
                                        <div class="send-wrap">
                                            <input type="submit" value="SEND MESSAGE" id="submit" name="submit" class="submit" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-12">
                            <div class="wprt-spacer" data-desktop="100" data-mobi="60" data-smobi="60" style={{ height: "100px" }}></div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default Contact
import React, { useEffect, useState } from 'react'
import "../assets/styles/services.css"
import { TiTick } from 'react-icons/ti';
import loft_after from "../assets/images/services/loft_after.jpg";
import loft_pre from "../assets/images/services/loft_pre.jpg"
import Sidebar from '../layout/Sidebar';

const LoftConversion = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const accordionData = [
        {
            title: "Design and Construction",
            content: "Our architects and builders collaborate to create a layout that meets your requirements while blending seamlessly with your home’s design. The process includes structural adjustments, insulation, windows, and often an en-suite bathroom, making the space fully functional and comfortable."
        },
        {
            title: "Final Touches and Quality Assurance",
            content: "We ensure every detail is handled with precision, from electrical work to flooring and finishes. Our team adheres to safety standards, delivering a loft conversion that’s both stylish and built to last. The result is a beautiful, cohesive space that boosts comfort and home value.."
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div id="featured-title" class="clearfix featured-title-left">
                <div id="featured-title-inner" class="container clearfix">
                    <div class="featured-title-inner-wrap">
                        <div class="featured-title-heading-wrap">
                            <h1 class="featured-title-heading">Loft Conversion</h1>
                        </div>
                        <div id="breadcrumbs">
                            <div class="breadcrumbs-inner">
                                <div class="breadcrumb-trail">
                                    <a href="#" title="Construction" rel="home" class="trail-begin">Home</a>
                                    <span class="sep">/</span>
                                    <span class="trail-end">Loft Conversion</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services_sec">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <Sidebar />
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <h3 class="line-height-normal margin-bottom-10">WHAT IS INCLUDED</h3>
                            <p class="margin-bottom-0">A loft conversion is an excellent way to add valuable space to your home without extending its footprint. Transforming an unused attic into a functional room—such as a bedroom, office, or playroom—can greatly enhance your living space and increase property value. Loft conversions are ideal for creating versatile, private areas that serve a variety of purposes, all while making the most of existing space.

                                The process begins with a structural assessment to determine the best type of conversion for your loft. Popular options include dormer, mansard, and hip-to-gable conversions, each offering unique benefits in terms of headroom, lighting, and layout. Skilled architects and builders then work closely with you to design a layout that meets your needs and blends seamlessly with the rest of your home.</p>

                            <div className="acc_sec services_acc">
                                {accordionData.map((item, index) => (
                                    <div key={index} className="accordion-item">
                                        <div className="accordion-title" onClick={() => toggleAccordion(index)}>

                                            <h4>{item.title}</h4>
                                            <span>{activeIndex === index ? "-" : "+"}</span>
                                        </div>
                                        {activeIndex === index && (
                                            <div className="accordion-content">
                                                <p>{item.content}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>


                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>


                            <div className="row">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={loft_pre} alt="" width="100%" />
                                    </div>
                                    <h3>Pre Construction</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quaerat corporis sint sunt! Ullam molestiae et voluptas atque. Ullam, mollitia?</p>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="img__card">
                                        <img src={loft_after} alt="" width="100%" />
                                    </div>
                                    <h3>After the Project</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid id quam modi a possimus natus voluptatem numquam voluptates ex dolorum?</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <div className="wprt-spacer" data-desktop="110" data-mobi="40" data-smobi="40" style={{ height: "110px" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoftConversion
import React, { useEffect } from 'react';
import { FaDraftingCompass, FaBriefcase, FaHeart, FaCheckCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';
import '../assets/styles/career.css';

const Career = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>


            <div id="featured-title" class="clearfix featured-title-left">
                <div id="featured-title-inner" class="container clearfix">
                    <div class="featured-title-inner-wrap">
                        <div class="featured-title-heading-wrap">
                            <h1 class="featured-title-heading">Career</h1>
                        </div>
                        <div id="breadcrumbs">
                            <div class="breadcrumbs-inner">
                                <div class="breadcrumb-trail">
                                    <a href="#" title="Construction" rel="home" class="trail-begin">Home</a>
                                    <span class="sep">/</span>
                                    <span class="trail-end">Career</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="career-page">
                <header className="career-header">
                    <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                        Join Our Team as a Designer
                    </motion.h1>
                    <p>Help us shape the future of construction through innovative design.</p>
                </header>

                <section className="job-details">
                    <h2><FaDraftingCompass /> Job Overview</h2>
                    <p>As a Designer at Loft Construction, you will work closely with architects, engineers, and clients to bring construction projects to life. Your role is crucial in transforming visions into reality with creative and functional designs.</p>
                </section>

                <section className="responsibilities">
                    <h3><FaBriefcase /> Responsibilities</h3>
                    <ul>
                        {[
                            "Develop detailed design plans and construction documents.",
                            "Collaborate with project teams to ensure cohesive designs.",
                            "Use software like AutoCAD, Revit, and SketchUp to model structures.",
                            "Present design concepts to clients and stakeholders.",
                            "Stay updated with industry trends and best practices."
                        ].map((item, index) => (
                            <motion.li key={index} initial={{ x: -50, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.1 * index }}>
                                <FaCheckCircle className="icon" /> {item}
                            </motion.li>
                        ))}
                    </ul>
                </section>

                <section className="qualifications">
                    <h3><FaCheckCircle /> Qualifications</h3>
                    <ul>
                        <li>Bachelor's degree in Architecture, Interior Design, or related field.</li>
                        <li>Proven experience with construction design software.</li>
                        <li>Excellent communication and presentation skills.</li>
                        <li>Attention to detail and strong problem-solving abilities.</li>
                    </ul>
                </section>

                <section className="benefits">
                    <h3><FaHeart /> What We Offer</h3>
                    <p>At Loft Construction, we offer a supportive and dynamic work environment with a commitment to professional growth.</p>
                    <ul>
                        <li>Competitive salary and health benefits</li>
                        <li>Flexible working hours</li>
                        <li>Opportunities for professional development</li>
                        <li>Collaborative and inclusive company culture</li>
                    </ul>
                </section>

                <section className="apply-now">
                    <h3>Ready to Apply?</h3>
                    <p>If you're passionate about designing innovative solutions in construction, we'd love to hear from you.</p>
                    <motion.button whileHover={{ scale: 1.1 }} className="apply-button" style={{ marginTop: "20px" }}>Apply Today</motion.button>
                </section>
            </div>
        </>
    )
}

export default Career
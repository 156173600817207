import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'



const Sidebar = () => {

    const location = useLocation();
    const currentItem = location.pathname.split("/").pop();

    return (
        <ul id="menu-service-menu" className="menu">
            <li className={`menu-item ${currentItem === "bedroom-kitchen-fitting" ? "current_page_item" : ""}`}>
                <Link to="/services/bedroom-kitchen-fitting">Bedroom & Kitchen Fitting</Link>
            </li>
            <li className={`menu-item ${currentItem === "brickwork-services" ? "current_page_item" : ""}`}>
                <Link to="/services/brickwork-services">BrickWorks Services</Link>
            </li>
            <li className={`menu-item ${currentItem === "home-extension" ? "current_page_item" : ""}`}>
                <Link to="/services/home-extension">Home Extension</Link>
            </li>
            <li className={`menu-item ${currentItem === "home-renovation" ? "current_page_item" : ""}`}>
                <Link to="/services/home-renovation">Home Renovation</Link>
            </li>
            <li className={`menu-item ${currentItem === "loft-conversion" ? "current_page_item" : ""}`}>
                <Link to="/services/loft-conversion">Loft Conversion</Link>
            </li>
            <li className={`menu-item ${currentItem === "new-builds" ? "current_page_item" : ""}`}>
                <Link to="/services/new-builds">New Builds</Link>
            </li>
        </ul>

    )
}

export default Sidebar
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./layout/Navbar";
import ResponsiveNavbar from "./layout/ResponsiveNavbar";
import Footer from "./layout/Footer";
import "./assets/styles/global.css"
import About from "./pages/About";
import Services from "./pages/Services";
import BedroomFitting from "./pages/BedroomFitting";
import BrickworksServices from "./pages/BrickworksServices";
import HomeExtension from "./pages/HomeExtension";
import HomeRenovation from "./pages/HomeRenovation";
import LoftConversion from "./pages/LoftConversion";
import NewBuilds from "./pages/NewBuilds";
import Projects from "./pages/Projects";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import Career from "./pages/Career";

const App = () => {
  return (
    <Router>
      <Navbar />
      <ResponsiveNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services/bedroom-kitchen-fitting" element={<BedroomFitting />} />
        <Route path="/services/brickwork-services" element={<BrickworksServices />} />
        <Route path="/services/home-extension" element={<HomeExtension />} />
        <Route path="/services/home-renovation" element={<HomeRenovation />} />
        <Route path="/services/loft-conversion" element={<LoftConversion />} />
        <Route path="/services/new-builds" element={<NewBuilds />} />

        <Route path="/project" element={<Projects />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
import React, { useState } from 'react'
import preconstructiom from "../assets/images/preconstructiom.jpg"
import project from "../assets/images/project.jpg"
import cons from "../assets/images/cons.webp"
import "../assets/styles/gallery.css"

const Gallery = () => {
    const images = [
        { id: 1, src: preconstructiom, alt: "Image 1" },
        { id: 2, src: project, alt: "Image 2" },
        { id: 3, src: cons, alt: "Image 3" },
        // Add more images as needed
    ];

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    // Function to open the modal and set the selected image
    const openModal = (image) => {
        setSelectedImage(image);
        setModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setSelectedImage(null);
        setModalOpen(false);
    };



    return (
        <>

            <div id="featured-title" class="clearfix featured-title-left">
                <div id="featured-title-inner" class="container clearfix">
                    <div class="featured-title-inner-wrap">
                        <div class="featured-title-heading-wrap">
                            <h1 class="featured-title-heading">Gallery</h1>
                        </div>
                        <div id="breadcrumbs">
                            <div class="breadcrumbs-inner">
                                <div class="breadcrumb-trail">
                                    <a href="#" title="Construction" rel="home" class="trail-begin">Home</a>
                                    <span class="sep">/</span>
                                    <span class="trail-end">Gallery</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="container">

                    <div className="gallery">
                        <div className="row">
                            {images.map((image) => (
                                <div className="col-lg-4 col-sm-12">
                                    <div className="gallery-card" onClick={() => openModal(image)}>
                                        <img src={image.src} alt={image.alt} className="gallery-image" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>


                    {isModalOpen && selectedImage && (
                        <div className="modal-overlay" onClick={closeModal}>
                            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                                <span className="close-button" onClick={closeModal}>&times;</span>
                                <img src={selectedImage.src} alt={selectedImage.alt} className="modal-image" />
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}

export default Gallery